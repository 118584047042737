import React from 'react';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Logo } from '../resources/images/logo.svg';

const useStyles = makeStyles(() => ({
  image: {
    maxHeight: '50px',
    width: 'auto',
    height: 'auto',
    "margin-bottom": "-5px",
    "margin-top": "5px",
    color: "rgb(68, 37, 78)",
  },
}));

const LogoImage = ({ color }) => {
  const classes = useStyles();
  //const color1="color: rgb(68, 37, 78);"
  //console.log(classes);
  return (<Logo className={classes.image} style={{ color }} />);
};

export default LogoImage;
